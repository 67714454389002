import logo from "../assets/images/logo.svg";
import { Link } from "react-router-dom";

function Header() {

  return (
    <header>
      <nav className="navbar navbar-expand-md navbar-light">
        <div className="container d-flex flex-column justify-content-center box-shadow">
          <div className="navbar-brand">
            <Link className="navbar-brand" to="/">
              <img src={logo} alt="Logo Le Kiosque Numerique" style={{ height: 70, width: 'auto' }} />
            </Link>
          </div>
          <div className="text-center mt-5">
            <h1 className="text-primary">30 mn pour…</h1>
            <p>….vous évader et lire un article de notre sélection de presse en ligne spéciale Rhônexpress</p>
          </div>
        </div>
      </nav>
    </header>
  );
}

export default Header;
