import { Issue } from "../components";
import { Link, } from "react-router-dom";
import { useCategoriesQuery } from "../stores/api";
import podcastImage from '../assets/images/podcast.png'
import photosInsta from '../assets/images/photos_insta.png'
import flight from '../assets/images/flight.svg'
import train from '../assets/images/train.svg'
import avisImage from '../assets/images/avis.jpeg'
import readerUrl from "../utils/readerUrl";
import useCountIssues from "../hooks/useCountIssues";

const MAX_ITEMS = 6;

const Home = () => {

  // const { data: allIssues } = useLastUpdatedissuesQuery();
  // const issues = useMemo(() => {
  //   if (allIssues) {
  //     return first(chunk(allIssues, MAX_ITEMS)) || []
  //   }
  //   return []
  // }, [allIssues])

  useCategoriesQuery()
  const issues = useCountIssues(MAX_ITEMS)
  const onClick = (issue: any) => () => {
    window.location.href = readerUrl(issue);
  }
  return (
    <div className="container">
      <div>
        <div className="row m-0">
          {issues.map((issue) => {
            return (
              <div key={issue.id} className="col-6 col-sm-4 col-md-2 p-1">
                <Issue
                  issue={issue.publicationNumber!}
                  onClick={onClick(issue.publicationNumber!)}
                />
              </div>
            );
          })}
        </div>

        {
          issues.length > 0 && (
            <div className="text-center my-3">
              <Link to="/publications" className="h3">Découvrez toute notre sélection de magazines en cliquant ici</Link>
            </div>
          )
        }
      </div>
      <div className="text-center mt-5">
        <p>{'…découvrir les podcasts « Rhônexpress <> Tout Lyon: "Ces lyonnais qui changent notre monde"'}</p>
        <a
          href="https://www.rhonexpress.fr/fr_FR/blog-rhonexpress/podcast2"
          target="_blank" rel="noreferrer">
          <img src={podcastImage} alt="Posdcast Rhonexpress" className="my-1 img img-fluid" />
        </a>
      </div>
      <div className="text-center mt-5">
        <p>{'…partager vos plus belles photos sur instragram #rhonexpress'}</p>
        <a
          href="https://www.instagram.com/rhonexpress_officiel/"
          target="_blank" rel="noreferrer">
          <img src={photosInsta} alt="Instagram Rhonexpress" className="my-1 img img-fluid" />
        </a>
      </div>
      <div className="text-center mt-5">
        <p>{'….vous assurer sur les horaires de votre vol ou de votre train en temps réel'}</p>
        <div className="d-flex justify-content-center align-items-center">
          <div className="me-2 align-items-stretch">
            <div className="travel-info">
              <a href="https://www.rhonexpress.fr/fr_FR/transport/flight" target={"_blank"} rel="noreferrer">
                <div className="nq-c-HomePushsTiny-icon">
                  <img src={flight} alt="Informations de vol" className="my-1 img img-fluid" />
                </div>
                <div><strong>Informations de vol</strong></div>
              </a>
            </div>
          </div>
          <div className="align-items-stretch">
            <div className="travel-info">
              <a href="https://www.rhonexpress.fr/fr_FR/transport/flight" target={"_blank"} rel="noreferrer">
                <div className="nq-c-HomePushsTiny-icon">
                  <img src={train} alt="Informations de train" className="my-1 img img-fluid" />
                </div>
                <div><strong>Informations de train</strong></div>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="text-center mt-5">
        <p className="h6">{'... et seulement quelques minutes pour répondre à une enquête de satisfaction sur le service Rhônexpress !'}</p>
        <a href="https://rhonexpress.qualtrics.com/jfe/form/SV_2nrmA434P2D5WDk" target={"_blank"} rel="noreferrer">
          <img src={avisImage} className="my-1 img img-fluid" style={{ width: 400 }} />
        </a>
      </div>
    </div>
  );
}

export default Home
