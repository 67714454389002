import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useCheckAvailabilityMutation, useCodeTokenMutation, useSaveRhoneInfoMutation } from '../../stores/api';
import { useIsAuthenticated } from '../../stores/authSlice';
import logo from "../../assets/images/logo.svg";

export default function KiokCode() {

    const { code, } = useParams()
    const [optinInfo, setOptinInfo] = useState(false)
    const [optinCGU, setOptinCGU] = useState(false)
    const navigate = useNavigate()

    const isAuthenticated = useIsAuthenticated()
    const [saveRhoneInfo, { isSuccess: infoSaved }] = useSaveRhoneInfoMutation()

    const handleSubmit = useCallback((e: React.SyntheticEvent) => {
        e.preventDefault();

        const target = e.target as typeof e.target & {
            email: { value: string };
            optinInfo: { checked: boolean };
            optinCGU: { checked: boolean };
        };

        saveRhoneInfo({
            email: target.email.value,
            optionInfo: target.optinInfo.checked,
            optionCGU: target.optinCGU.checked,
        })
    }, [saveRhoneInfo])

    useEffect(() => {
        if (isAuthenticated) {
            navigate('/', { replace: true })
        }
    }, [isAuthenticated, navigate])

    const [
        checkCodeAvailability,
        {
            isLoading: isCheckingCode,
            error: checkingCodeError,
            data: checkCodeData
        }
    ] = useCheckAvailabilityMutation()
    const [fetchCodeToken, { isLoading, }] = useCodeTokenMutation()
    useEffect(() => {
        if (code && infoSaved) {
            checkCodeAvailability(code)
        }
    }, [code, infoSaved, checkCodeAvailability])

    useEffect(() => {
        if (checkCodeData?.available && code) {
            fetchCodeToken(code)
        }
    }, [fetchCodeToken, checkCodeData?.available, code])

    const Loading = useMemo(() => {
        if (isCheckingCode || isLoading) {
            return (
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}>
                    <h4  >Verification...</h4>
                </div>
            )
        }
        return null;
    }, [isCheckingCode, isLoading])

    const CodeUnAvailable = useMemo(() => {
        if (checkCodeData?.available === false) {
            return (
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        minHeight: '100vh'
                    }}>
                    <h4
                        className='danger text-center' >
                        {/* @ts-ignore */}
                        {checkingCodeError?.data?.message || "Vous n'êtes plus éligible pour accéder au kiosque"}
                    </h4>
                </div>
            )
        }
        return null;
    }, [checkCodeData?.available, checkingCodeError])

    const form = useMemo(() => {
        return (
            <div className='contrain' style={{ maxWidth: 500 }}
                onSubmit={handleSubmit}
            >
                <form method='POST' className='row g-3 needs-validation'>
                    <div className='contain'>
                        <p className='text-center'>
                            Poursuivez votre navigation ! Accédez au bouquet de presse de notre partenaire, aux podcasts Rhônexpress et consultez les infos de votre vol ou votre train en temps réels !
                        </p>
                    </div>
                    <div className="mb-3">
                        <label htmlFor="email" className="form-label">Email address</label>
                        <input type="email" className="form-control" id="email" placeholder="name@example.com" required />
                    </div>
                    <div className="form-check">
                        <input className="form-check-input"
                            defaultChecked={optinInfo}
                            onChange={(e) => setOptinInfo(e.target.checked)}
                            type="checkbox" name="optinInfo" id="optinInfo" />
                        <label className="form-check-label" htmlFor="optinInfo">
                            Je veux recevoir des informations de la part de Rhônexpress
                        </label>
                    </div>
                    <div className="form-check">
                        <input className="form-check-input"

                            defaultChecked={optinCGU}
                            onChange={(e) => setOptinCGU(e.target.checked)}
                            type="radio" name="optinCGU" id="optinCGU" required />
                        <label className="form-check-label" htmlFor="optinCGU">
                            {/* <a href='https://cgu.kioskpress.fr/' target={"_blank"} >J'accepte condition générale d'utilisation</a> */}
                            En cochant cette case je reconnais avoir lu et accepté les  <a href='https://www.rhonexpress.fr/fr_FR/conditions-generales-d-utilisation-du-site' target={"_blank"} rel="noreferrer">Conditions Générales d’Utilisation</a> et les règles relatives aux traitements de mes <a href='https://www.rhonexpress.fr/fr_FR/confidentialite' target={"_blank"} rel="noreferrer" >Données à Caractère Personnel</a>
                        </label>
                    </div>
                    <div className="col-12 text-center">
                        <button className="btn btn-primary" type="submit">Accéder à la presse</button>
                    </div>
                </form>
            </div>
        )
    }, [handleSubmit, optinCGU, optinInfo])
    return (
        <div className="vh-100 d-flex flex-column px-4 justify-content-center align-items-center bg-light">
            <img src={logo} alt="Logo Le Kiosque Numerique" style={{ height: 70, width: 'auto' }} className='m-4' />

            {Loading}
            {form}
            {CodeUnAvailable}
        </div >
    );
}