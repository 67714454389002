import logo from "../../assets/images/logo.svg";

import marcel from '@/assets/images/marcel.png'
export default function Unavailable() {


    return (
        <div className="container">
            <div className="vh-100 d-flex flex-column px-4 justify-content-center align-items-center bg-light"
                style={{
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                    backgroundPositionX: '30%',
                    backgroundPositionY: '80%',
                }}>

                <img src={logo} style={{ height: 100, maxWidth: '100%', marginBottom: 20 }} />
                <h5 className="text-center" >Vous n'est plus éligible pour accéder à la presse</h5>
            </div>
        </div >
    );
}