import React from 'react';
import ReactDOM from 'react-dom';
import './App.scss';
import "bootstrap";
import reportWebVitals from './reportWebVitals';
import { withStores } from './stores';
import { configureApp } from './config/configApp';
import App from './App';
import dayjs from 'dayjs';
import 'dayjs/locale/fr';
import localizedFormat from 'dayjs/plugin/localizedFormat';

dayjs.locale('fr')
dayjs.extend(localizedFormat)

configureApp()
const AppWithStore = withStores(App)
ReactDOM.render(
  <React.StrictMode>
    <AppWithStore />
  </React.StrictMode>,
  document.getElementById('app')
);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
