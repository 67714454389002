import { map } from "lodash";
import { Issue } from "../components";
import { withStores, } from "../stores";
import readerUrl from "../utils/readerUrl";
import { Issue as IssueType } from "../stores/types";
import { useCategoriesQuery, } from "../stores/api";


const Kiosk = () => {

  const { isFetching, data: categories } = useCategoriesQuery();

  const onClick = (issue: IssueType) => () => {
    window.location.href = readerUrl(issue);
  }
  return (
    <div className="min-vh-100">
      <div className="mt-3" />

      {isFetching && (
        <div className="container d-flex justify-content-center min-vh-100 align-items-center">
          <i className="fas fa-spinner fa-spin fa-10x" />
        </div>
      )}

      <div className="container">
        {map(categories, (category) => {
          return (
            <div
              className="category mt-4"
              key={category.id}
              id={`category-${category.id}`}
            >
              {/* <div className="mb-4">
                <h4 className="text-secondary">{category.name}</h4>
                <h6>{category.subtitle}</h6>
              </div> */}
              <div className="row m-0">
                {map(category.items, (issue) => {

                  if (!issue) {
                    return null;
                  }
                  return (
                    <div key={issue.id} className="col-6 col-sm-4 col-md-2 p-1">
                      <Issue
                        issue={issue.publicationNumber!}
                        onClick={onClick(issue.publicationNumber!)}
                      />
                    </div>
                  );
                })}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default withStores(Kiosk)
