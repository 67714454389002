
import axios from 'axios';
import { camelizeKeys } from 'humps';
import { merge } from 'lodash';

export const API_KEY = process.env.REACT_APP_API_SECRETE!
export const API_BASE_URL = process.env.REACT_APP_API_URL!

export const BASE_API_CONFIG = {
    baseURL: API_BASE_URL,
    params: {
        kiosk: process.env.REACT_APP_KIOSK_ID
    },
    headers: {
        locale: 'fr',
        'X-Accept-Version': '3',
    }
}
export const baseApi = axios.create(BASE_API_CONFIG);

export const authApi = axios.create(merge(BASE_API_CONFIG, { headers: { Authorization: API_KEY } }))


baseApi.interceptors.response.use(response => {

    return {
        ...response,
        data: camelizeKeys(response.data)
    };
}, async error => {
    return Promise.reject(camelizeKeys(error))
})

authApi.interceptors.response.use(response => {

    return {
        ...response,
        data: camelizeKeys(response.data)
    };
}, async error => {
    return Promise.reject(camelizeKeys(error))
})
export const configureApp = async () => {

}