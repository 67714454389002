import { useRef } from "react";
import { Issue as IssueType } from "../stores/types";
import imageNotFound from "../assets/images/image-not-found.png";
import dayjs from "dayjs";

interface Props {
  issue: IssueType
  onClick?: (issue: IssueType) => void
}
const Issue = (props: Props) => {
  const { issue, onClick } = props;
  const _imgRef = useRef(null!);

  const onError = (e: any) => {
    e.target.src = imageNotFound;
  };


  if (!issue) {
    return null;
  }

  return (
    <div className="issue">
      <img
        ref={_imgRef}
        alt={"Issue"}
        loading="lazy"
        data-not-found
        className="img img-fluid img-lg"
        src={issue?.coverUrl}
        onError={onError}
        onClick={() => onClick && onClick(issue)}
      />
      <div className="d-flex flex-row align-items-center">

        <div className="info text-truncate" onClick={() => onClick && onClick(issue)}>

          <p className="title"  >{issue?.publicationName}</p>
          <p className="published_at">
            {issue?.dateParution
              ? dayjs(issue?.dateParution).format("ll")
              : "\u00A0"}
          </p>
        </div>
      </div>
    </div>
  );
};


export default Issue;
