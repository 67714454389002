import { createListenerMiddleware, isAnyOf } from "@reduxjs/toolkit";
import { api } from "../api";
 
export const tokenMiddleware = createListenerMiddleware();
// Add one or more listener entries that look for specific actions.
// They may contain any sync or async logic, similar to thunks.
tokenMiddleware.startListening({
    matcher: isAnyOf(api.endpoints.checkAvailability.matchFulfilled),
    effect: async (action, listenerApi) => {
        if (action.payload.available) {
            listenerApi.dispatch(api.endpoints.codeToken.initiate(action.meta.arg.originalArgs))
        } else {
            listenerApi.dispatch(api.util.invalidateTags(['Profile']))
        }
    },
});

