
import { createSlice } from '@reduxjs/toolkit'
import { useAppSelector } from '../stores';
import { api } from './api';

export interface GenerateCodeSlice {
    code?: string
}

const initialState: GenerateCodeSlice = {}

export const generateCodeSlice = createSlice({
    name: 'generate-code-slice',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addMatcher(api.endpoints.kioskAccessCodes.matchFulfilled, (state, action) => {

                state.code = action.payload.code
            })
    }
})

export const useAvailableCode = () => useAppSelector(state => state.generatedCode.code)