import { createListenerMiddleware, isAnyOf } from "@reduxjs/toolkit";
import { api } from "../api";
import { logout, setCode } from "../authSlice";

export const accountMiddleware = createListenerMiddleware();
// Add one or more listener entries that look for specific actions.
// They may contain any sync or async logic, similar to thunks.
accountMiddleware.startListening({
    matcher: isAnyOf(logout, setCode),
    effect: async (action, listenerApi) => {
        if (logout.match(action)) {

            listenerApi.dispatch(api.util.invalidateTags(['Profile', 'Kiosk']))
        }
        else if (setCode.match(action)) {

            listenerApi.dispatch(api.util.invalidateTags(['Kiosk']))
            if (action.payload) {
                listenerApi.dispatch(api.endpoints.checkAvailability.initiate(action.payload))
            } else {
                listenerApi.dispatch(api.util.invalidateTags(['Profile']))

            }
        }
    },
});

