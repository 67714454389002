import React from 'react';
import { combineReducers, configureStore } from '@reduxjs/toolkit'
import { setupListeners } from '@reduxjs/toolkit/dist/query'
import { Provider, TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'
import { api } from './api';
import { authSlice } from './authSlice';
import expireReducer from './transformers/expire';
import {
    persistReducer,
    persistStore,
    FLUSH,
    REHYDRATE,
    PAUSE,
    PERSIST,
    PURGE,
    REGISTER,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { kebabCase } from 'lodash';
import pkg from '../../package.json';
import { accountMiddleware } from './middlewares/account';
import { tokenMiddleware } from './middlewares/token';
import { PersistGate } from 'redux-persist/integration/react';
import { generateCodeSlice } from './generateCodeSlice';


const expiresAuth = expireReducer("auth", {
    autoExpire: true,
    expireSeconds: 1 * 60 * 60 * 24, // => 1 jours ==> (countDays * 60seconds*60minuts*24hours (seconds per day))
    // expireSeconds: 120, // => 3 jours ==> (countDays * 60seconds*60minuts*24hours (seconds per day))
    expiredState: {}, // Reset to empty array after expiry
});

const expiresGeneratedCode = expireReducer("auth", {
    autoExpire: true,
    expireSeconds: 60 * 60 * 24, //=> 24H
    expiredState: {}, // Reset to empty array after expiry
});
const reducers = combineReducers({
    auth: authSlice.reducer,
    generatedCode: generateCodeSlice.reducer,
    [api.reducerPath]: api.reducer,
});
const reducer = persistReducer({
    key: kebabCase(pkg.name),
    version: 1,
    storage: storage,
    debug: false,
    whitelist: ['auth', 'generatedCode'],
    transforms: [
        expiresAuth,
        expiresGeneratedCode
    ],
}, reducers)


export const store = configureStore({
    reducer: reducer,

    // middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(kuntoApi.middleware),
    middleware: (getDefaultMiddleware) => {
        return getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
            },
        })
            .concat(
                api.middleware,
                accountMiddleware.middleware,
                tokenMiddleware.middleware
            )
    },
})
export const hydrateStores = (): Promise<void> => {
    return new Promise((resolve) => {
        persistStore(store, null, () => {
            resolve()
        });
    })
};

setupListeners(store.dispatch)

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
let persistor = persistStore(store)

export const withStores = (Component: React.ElementType) => {
    return (props: any): React.ReactElement => {


        return (
            <Provider store={store} >
                <PersistGate loading={null} persistor={persistor} >
                    <Component {...props} />
                </PersistGate>
            </Provider>
        )
    };
};
// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = () => useDispatch<AppDispatch>()
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector