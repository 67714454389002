import React, { ComponentClass, FunctionComponent } from "react";

import {
  HashRouter as Router,
  Route,
  Routes,
} from "react-router-dom";

import Kiosk from "./Kiosk";

import { Header, Footer } from "../components";

import Home from "./Home";
import Unavailable from "./unavailable";
import { useIsAuthenticated } from "../stores/authSlice";
import KiokCode from "./kiosk-code";

const WrapperRouter = ({
  component,
  showHeader,
  showFooter,
  isPrivate = true,
  ...rest
}: {
  component: string | FunctionComponent<{}> | ComponentClass<{}, any>,
  showHeader?: boolean,
  showFooter?: boolean,
  isPrivate?: boolean,
}) => {

  const isAuth = useIsAuthenticated()
  // console.log({isPrivate})
  if (isPrivate === true && !isAuth) {

    return React.createElement(Unavailable)
  }
  return (
    <React.Fragment>
      {showHeader && <Header />}
      {(showHeader || showFooter) && (
        <main className="flex-shrink-0">

          {React.createElement(component, rest)}
        </main>
      )}
      {!showHeader && !showFooter && React.createElement(component, rest)}
      {showFooter && <Footer />}
    </React.Fragment>
  )
};

WrapperRouter.defaultProps = {
  showHeader: true,
  showFooter: true,
};

const EntryApp = () => {

  return (
    <Router>
      <Routes>

        <Route path="/" element={<WrapperRouter component={Home} />} />
        <Route path="publications" element={<WrapperRouter component={Kiosk} />} />
        <Route path="/access/:code" element={
          <WrapperRouter
            component={KiokCode}
            showFooter={false}
            showHeader={false}
            isPrivate={false} />
        } />
        <Route path="*" element={<WrapperRouter component={Home} />} />

      </Routes>
    </Router>
  );
};

export default EntryApp;