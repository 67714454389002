import moment from "moment";
// import LKN from "images/lkn.svg";
import GroupeProPress from "../assets/images/groupepropress-v.svg";
function Footer() {
  return (
    <footer className="footer mt-5 py-3 bg-light">
      {/* <hr /> */}
      <div className="container">
        <div className="d-flex justify-content-center align-items-center text-center">
          <div>
            {/* <img src={LKN} alt="Le Kiosque Numérique" /> */}
            <img
              src={GroupeProPress}
              className="img img-fluid"
              alt="Le Kiosque Numérique"
              style={{ width: 200 }}
            />
            <div className="text-truncate">
            © {moment().format("Y")} - Tous droits réservés Groupe ProPress
          </div>
          </div>
          
        </div>
      </div>
    </footer>
  );
}

export default Footer;
