
import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { merge } from 'lodash';
import { useAppSelector } from '../stores';
import { api } from './api';

export interface AuthState {
  [key: string]: any
  token?: string | null
  code?: string | null
}

const initialState: AuthState = {}
export const logout = createAction<void>('logout')

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setCode(state, action: PayloadAction<string>) {
      state.code = action.payload
    },
    setValue(state, action: PayloadAction<{ key: string, value: any }>) {
      const { key, value } = action.payload;
      state[key] = value
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(logout, (state) => {
        merge(state, {}, initialState)
      })
 
      .addMatcher(api.endpoints.checkAvailability.matchFulfilled, (state, action) => {

        if (!action.payload.available) {
          state.code = null
          state.token = null
        }

      })
      .addMatcher(api.endpoints.checkAvailability.matchRejected, (state) => {
        state.code = null
        state.token = null
      })
      .addMatcher(api.endpoints.codeToken.matchFulfilled, (state, action) => {

        state.token = action.payload.token
      })
      ;
  }
})


export const {
  setCode,
  setValue
} = authSlice.actions

export const useIsAuthenticated = () => useAppSelector(state => Boolean(state.auth.token))